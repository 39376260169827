th {
  border: 1px solid rgba(241, 243, 244, 1);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dot-color{
  width: 24px;
  height: 24px;
  color: #4BB543;
}

.dotn-color{
  width: 24px;
  height: 24px;
  color: #fff;
}

.spinner {
  margin: auto;
  width: 214px;
  height: 200px;
  text-align: center;
  font-size: 10px;
}
.spinner > div {
  height: 100%;
  width: 40px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.rect1 {
  background-color: rgb(210, 47, 40);
}

.spinner .rect2 {
    background-color: rgb(199, 158, 87);
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.15s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1.05s;
  background-color: rgb(45, 120, 90);
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  50%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  25% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-stretchdelay {
  0%,50%,100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  25% {
    transform: scale(1);
    -webkit-transform: scaleY(1);
  }
}
